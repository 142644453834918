import { Box, Button, TextField, Select, MenuItem, useMediaQuery, InputLabel, Typography, useTheme } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Header from "../global/Header";
import { SelectChangeEvent } from "@mui/material";
import React from 'react'
import { useAddNewUserMutation } from "../../../features/users/usersApiSlice";
import { useState } from "react";
import { tokens } from "../../../theme"
import { useParams, useNavigate } from "react-router-dom"
import useAuth from "../../../hooks/useAuth";
import { useGetAtgsQuery } from "../../../features/atgs/atgsApiSlice";


const initialValues = {
    name: "",
    username: "",
    password: "",
    email: "",
    phone: "",
    role: ""

}
//go back for email validation
const userSchema = yup.object().shape({
    name: yup.string(), //.required("Required"),
    username: yup.string(),//.required("Required"),
    password: yup.string(), //.required("Required"),
    email: yup.string(), //.required("Required"),
    phone: yup.string(),
    role: yup.string() //.required("Required"),
})



const UserForm = () => {
    const { username, isManager, isAdmin } = useAuth()
    const theme = useTheme();
    const navigate = useNavigate(); // Initialize the navigate function
    const colors = tokens(theme.palette.mode);
    const params = useParams()
    var organization_id = params.organization_id
    const org_user = !(!organization_id) //True if adding an org user
    //console.log("Organization ID: ", params.organization_id)
    //console.log("org_user ID: ", org_user)
    const isNonMobile = useMediaQuery("(min-width:600px");

    function filterByOrg(obj) {
        //console.log("OBJ: ", obj)
        if (obj.organization_id === organization_id) {
            return true
        }
    }

    const {
        data: atgs,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAtgsQuery('atgs', {
        pollingInterval: 15000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })
    let filtered_atgs = [];
    if (isSuccess) {

        //console.log("Loading Done")
        if (atgs && atgs.entities) {
            //console.log("Loading Done");
            var atg_ents = [];
            if (atgs.entities && typeof atgs.entities === 'object') {
                atg_ents = Object.values(atgs.entities).map(atg => {
                    return {
                        ...atg,
                        name: atg.attributes.name.replace(/_/g, ' '),
                        address: atg.attributes.address.replace(/_/g, ' ')
                    };
                });
            }
            filtered_atgs = atg_ents.filter(filterByOrg);
        }
    }

    const [addNewUser, {
    }] = useAddNewUserMutation()

    const handleFormSubmit = (values) => {
        //console.log(values)
        const name = values.name
        const username = values.username
        const password = values.password
        const email = values.email
        const phone = values.phone


        var role = selected

        if (role === 'Admin') {
            organization_id = 'admin'
        }

        if (role === 'User') {
            organization_id = params.organization_id
        }
        console.log("Full user: ", { name, username, password, email, phone, role, organization_id, selectedATGs });
        addNewUser({ 
            name, 
            username, 
            password, 
            email,
            phone,
            role, 
            organization_id, 
            authorizedAtgs: selectedATGs  // Add this line
        })
            .then(() => {
                navigate('/console/users'); // Navigate to '/console/users' upon successful submission
            })
            .catch((error) => {
                console.log(error)// Handle the error here
            });
    }

    const [selected, setSelected] = useState([]);
    const [selectedATGs, setSelectedATGs] = useState([]);

    const handleATGChange = (event) => {
        setSelectedATGs(event.target.value);
    };

    const selectionChangeHandler = (event) => {
        setSelected(event.target.value);
    };


    return (
        <Box
            m="20px">
            <Header title={`REGISTER USER`} subtitle="Create a new user" />

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={userSchema}>
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            sx={{
                                "& > div": { gridColumn: isNonMobile ? undefined : "span 4" }
                            }}>
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                                name="name"
                                error={!!touched.name && !!errors.name}
                                helperText={touched.name && errors.name}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Username"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.username}
                                name="username"
                                error={!!touched.username && !!errors.username}
                                helperText={touched.username && errors.username}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.email}
                                name="email"
                                error={!!touched.email && !!errors.email}
                                helperText={touched.email && errors.email}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <TextField
                                fullWidth
                                variant="filled"
                                type="text"
                                label="Phone"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.phone}
                                name="phone"
                                error={!!touched.phone && !!errors.phone}
                                helperText={touched.phone && errors.phone}
                                sx={{ gridColumn: "span 2" }}
                            />
                            <InputLabel name="role-label">Role</InputLabel>
                            <Select
                                id="role"
                                name="role"
                                value={selected}
                                onChange={selectionChangeHandler}
                            >
                                {isAdmin === true && <MenuItem value="Admin">Admin</MenuItem>}
                                <MenuItem value="Manager">Manager</MenuItem>
                                <MenuItem value="User">User</MenuItem>
                            </Select>
                            {((selected === 'Manager' || selected === 'User') && !org_user) &&
                                <TextField
                                    fullWidth
                                    variant="filled"
                                    type="text"
                                    label="Organization ID"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.organization_id}
                                    name="organization_id"
                                    error={!!touched.organization_id && !!errors.organization_id}
                                    helperText={touched.organization_id && errors.organization_id}
                                    sx={{ gridColumn: "span 2" }}
                                />
                            }
                            {org_user &&
                                <Typography variant="h4" color={colors.greenAccent[500]}>
                                    Organization ID: {organization_id}
                                </Typography>
                            }
                            {selected === 'User' &&
                                <Box display="flex" alignItems="center">
                                    <InputLabel name="atg-label" style={{ marginRight: '8px' }}>Stations</InputLabel>
                                    <Select
                                        multiple
                                        value={selectedATGs}
                                        onChange={handleATGChange}
                                        style={{ width: '200px' }}  // Set default width here
                                    >
                                        {filtered_atgs.map((atg) => (
                                            <MenuItem key={atg.thingName} value={atg.thingName}>
                                                {atg.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            }
                        </Box>
                        <Box display="flex" justifyContent="end" mt="20px">
                            <Button type="submit" color="secondary" variant="contained">
                                Create New User
                            </Button>
                        </Box>
                    </form>
                )}
            </Formik>
        </Box>
    )
}

export default UserForm